import { graphql, Link } from 'gatsby'
import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'

const PrivacyPolicy = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  return (
    <>
      <Layout location={location} title={siteTitle}>
        <SEO title='Privacy Policy' />
        <div className='page-privacy-policy'>
          <h1>
            Privacy Policy
          </h1>
          <h2>
            当サイトに掲載されている広告について
          </h2>
          <p>
            typememo.jp は
            Amazon.co.jp アソシエイトプログラムの参加者です．
          </p>
          <Link
            to='/'
          >
            Back to Home
          </Link>
        </div>
      </Layout>
    </>
  )
}
export default PrivacyPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
